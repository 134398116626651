<template>
    <div class="page_container">
        <div class="page_logo" @click="goHome()"></div>
        <div class="card_container">
            <div class="card_title">Sign up on <span class="believe">BELIEVE</span></div>
            <form @submit.prevent="register()">

                <div class="input_item">
                    <b-form-input
                            v-model="userForm.name"
                            type="text"
                            placeholder="Enter your name"
                            :class="{ 'is-invalid': isSubmitted && $v.userForm.name.$error }"
                            trim
                    >
                    </b-form-input>
                    <div v-if="isSubmitted && $v.userForm.name.$error" class="invalid-feedback">
                        <span v-if="!$v.userForm.name.required">Name field is required</span>
                    </div>
                </div>
                <div class="input_item">
                    <b-form-input
                            v-model="userForm.surname"
                            type="text"
                            placeholder="Enter your surname"
                            :class="{ 'is-invalid': isSubmitted && $v.userForm.surname.$error }"
                            trim
                    >
                    </b-form-input>
                    <div v-if="isSubmitted && $v.userForm.surname.$error" class="invalid-feedback">
                        <span v-if="!$v.userForm.surname.required">Surname field is required</span>
                    </div>
                </div>
              <div class="input_item">
                <b-form-select
                  v-model="userForm.school_type"
                  :options="school_type"
                  value-field="value"
                  text-field="label"
                  :class="{ 'is-invalid': isSubmitted && $v.userForm.school_type.$error }"
                >
                  <template #first>
                    <b-form-select-option value="null" disabled>Choose School</b-form-select-option>
                  </template>
                </b-form-select>
                <div v-if="isSubmitted && $v.userForm.school_type.$error" class="invalid-feedback">
                  <span v-if="!$v.userForm.school_type.required">School type field is required</span>
                </div>
              </div>

                <div class="input_item">
                    <b-form-input
                            id="email"
                            v-model="userForm.email"
                            type="email"
                            placeholder="Enter your email"
                            :class="{ 'is-invalid': isSubmitted && $v.userForm.email.$error }"
                            trim
                    >
                    </b-form-input>
                    <div v-if="isSubmitted && $v.userForm.email.$error" class="invalid-feedback">
                        <span v-if="!$v.userForm.email.required">Email field is required</span>
                        <span v-if="!$v.userForm.email.email">Please provide valid email</span>
                    </div>
                </div>
                <div class="input_item">
                    <b-form-input
                            id="password"
                            :type="l_password"
                            v-model="userForm.password"
                            placeholder="Enter your password"
                            :class="{ 'is-invalid': isSubmitted && $v.userForm.password.$error }"
                            trim
                    ></b-form-input>
                    <div :class="{'eye_close': l_password == 'password', 'eye_open': l_password == 'text',}"
                         @click="showPassword()"></div>
                    <div v-if="isSubmitted && $v.userForm.password.$error" class="invalid-feedback">
                        <span v-if="!$v.userForm.password.required">Password field is required</span>
                        <span v-if="!$v.userForm.password.minLength">Password should be at least 6 characters long</span>
                    </div>
                </div>

                <b-button class="sign_btn" type="submit">Register</b-button>
                <div v-if="error_msg" class="text_danger mt-1">{{error_msg}}</div>
                <div v-if="success_msg" class="text_success mt-2">{{success_msg}}</div>
<!--                <div class="forgot_container">-->
<!--                    <div class="forgot_pass" id="forgot-btn" @click="forgotPassword()">Forgot your password</div>-->
<!--                    <p class="forgot_options">or use one of these options</p>-->
<!--                </div>-->
<!--                <div class="options_btn-container">-->
<!--                    <div class="options_btn google" @click="otherOption('google')"></div>-->
<!--                    <div class="options_btn apple" @click="otherOption('apple')"></div>-->
<!--                    <div class="options_btn microsoft" @click="otherOption('microsoft')"></div>-->
<!--                    <div class="options_btn system" @click="otherOption('system')"></div>-->
<!--                </div>-->
                <div class="account_container">
                    <div class="account_text">Do you have an account? &nbsp;<router-link to="/sign_in"><div class="sign_text"> Log In</div></router-link></div>
                    <div class="account_faq">After you read our&nbsp;<div class="faq_text">FAQ</div>&nbsp;you can reach out for technical assistance at</div>
                </div>

            </form>

        </div>
    </div>
</template>

<script>
    import {required, email, minLength} from "vuelidate/lib/validators";
    import {mapActions} from "vuex";
    export default {
        name: "SignUp",
        data() {
            return {
                userForm: {
                    name: "",
                    surname: "",
                    school_type: null,
                    email: "",
                    password: ""
                },
                school_type: [
                  {
                    label: 'Primary School',
                    value: 'primary'
                  },
                  {
                    label: 'Middle School',
                    value: 'middle'
                  },
                  {
                    label: 'High School',
                    value: 'high'
                  },
                  {
                    label: 'Middle and High School',
                    value: 'middle_high'
                  }
                ],
                error_msg: null,
                success_msg: null,
                isSubmitted: false,
                l_password: 'password',
            }
        },
        validations: {
            userForm: {
                name: {
                   required
                },
                surname: {
                  required
                },
                school_type: {
                  required
                },
                email: {
                    required,
                    email
                },
                password: {
                    required,
                    minLength: minLength(6)
                },
            }
        },
        methods: {
            ...mapActions(['registerUser']),
            goHome() {
                this.$router.push('/')
            },
            showPassword() {
                if (this.l_password === 'password') {
                    this.l_password = 'text'
                } else {
                    this.l_password = 'password'
                }
            },
            // forgotPassword() {
            //     console.log('forgotPassword')
            // },
            otherOption(option) {
                console.log(option)
            },
            register() {
                this.isSubmitted = true;
                this.$v.$touch();
                if (this.$v.$invalid) {
                    return;
                } else {
                    this.error_msg = null
                    this.success_msg = null
                    this.registerUser(this.userForm)
                        .then((res) => {
                            if (res.status == 200 || res.status == 201) {
                                this.success_msg = 'Account created successfully'
                                setTimeout(() => {
                                    this.success_msg = null
                                    this.$router.push({ path: "/sign_in" } )
                                }, 3000)
                            } else {
                                this.error_msg = res.response.data.data.email[0]
                            }
                        })
                }
            }
        }
    }
</script>

<style scoped lang="scss">
.page {
    &_container {
        width: 100vw;
        height: 100vh;
        background: url("~@/assets/images/background_2.png");
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        display: flex;
        justify-content: center;
        align-items: center;
        & .card {
            &_container {
                width: 695px;
                height: 780px;
                background: #ffffff;
                border-radius: 50px;
                padding: 100px;
                display: flex;
                flex-direction: column;
                align-items: center;
              @media screen and (max-width: 1440px) {
                max-height: 740px;
                height: 100%;
                padding: 50px 100px;
              }
                & form {
                    width: 100%;
                }
                & .input_item {
                    padding: 10px 0;
                    width: 100%;
                    & input {
                        width: 100%;
                        height: 57px;
                        border-radius: 30px;
                        border: 1px solid #EEE;
                        padding: 0 26px;
                        &::placeholder {
                            color: #333;
                            font-family: 'Poppins', sans-serif;
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: normal;
                        }
                    }
                  & select {
                    width: 100%;
                    height: 57px;
                    border-radius: 30px;
                    border: 1px solid #EEE;
                    padding: 0 26px;
                    background: #fafafa;
                  }
                }
                & .sign_btn {
                    margin-top: 10px;
                    width: 100%;
                    height: 57px;
                    border-radius: 30px;
                    background: #C2A9FD;
                    border: none;

                }
                & .form-control:focus {
                    outline: none;
                    box-shadow: none;
                    background: #FAFFBD;
                }
                & .forgot {
                    &_container {
                        padding: 20px 0;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                    }
                    &_pass {
                        color: #522EA8;
                        font-family: 'Poppins', sans-serif;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                        text-decoration-line: underline;
                        cursor: pointer;
                    }
                    &_options {
                        padding-top: 17px;
                    }
                }
                & .options_btn {
                    width: 93px;
                    height: 93px;
                    cursor: pointer;
                    &-container {
                        width: 100%;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                    }
                    &.google {
                        background: url("~@/assets/images/google_btn.svg") no-repeat  center;
                        background-size: contain;
                    }
                    &.apple {
                        background: url("~@/assets/images/apple_btn.svg") no-repeat  center;
                        background-size: contain;
                    }
                    &.microsoft {
                        background: url("~@/assets/images/microsoft_btn.svg") no-repeat  center;
                        background-size: contain;
                    }
                    &.system {
                        background: url("~@/assets/images/system_btn.svg") no-repeat  center;
                        background-size: contain;
                    }
                }
                & .account {
                    &_container {
                        padding: 34px 0;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                    }
                    &_text {
                        display: flex;
                        color: #000;
                        font-family: 'Poppins', sans-serif;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: normal;
                        & a {
                            opacity: 0.7;
                            color: #846ABF;
                            font-family: 'Poppins', sans-serif;
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 600;
                            line-height: normal;
                            text-decoration-line: underline;
                        }
                    }
                    &_faq {
                        color: #000;
                        font-family: 'Poppins', sans-serif;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: normal;
                        display: flex;
                        padding-top: 20px;
                        & .faq_text {
                            opacity: 0.7;
                            color: #A694CF;
                            font-family: 'Poppins', sans-serif;
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 600;
                            line-height: normal;
                        }
                    }
                }
            }
            &_title {
                color: #333;
                font-size: 26px;
                font-weight: 600;
                padding-bottom: 26px;
                & .believe {
                    font-family: 'Poppins', sans-serif;
                    font-size: 26px;
                    font-weight: 300;
                }
            }
        }
    }
    &_logo {
        position: absolute;
        top: 50px;
        left: 50px;
        width: 72px;
        height: 72px;
        background: url("~@/assets/images/logo_small.svg");
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        cursor: pointer;
    }
}
::v-deep .form-control.is-invalid {
    background-image: none !important;
}
</style>
