<template>
  <div class="home_page">
    <div class="home_head">
      <div class="home_head-logo_big"></div>
      <h1 class="home_head-title">
        E.V.A.
      </h1>
      <div class="home_head-logo_small">
        <div class="logo_small"></div>
        <div class="logo_text">
          <div>Educational Virtual</div>
          <div>Assistant</div>
        </div>
      </div>
    </div>

    <div class="home_title">
      <h3>I risultati che ottieni dipendono da te. </h3>
      <h3>Comincia il viaggio. </h3>

    </div>
    <div class="home_btn-container">
      <b-button class="home_btn sign_up" @click="signUp()">SIGN UP</b-button>
      <b-button class="home_btn sign_in" @click="signIn()">SIGN IN</b-button>

    </div>

  </div>
</template>

<script>
// @ is an alias to /src


export default {
  name: 'Home',
  components: {

  },
  methods: {
    signIn() {
      this.$router.push('/sign_in')
    },
    signUp() {
      this.$router.push('/sign_up')
    }
  }
}
</script>
<style lang="scss" scoped>
  .home {
    &_page {
      width: 100vw;
      height: 100vh;
      background: #00A99D;
      padding: 58px 120px 58px 150px;
      @media screen and (max-width: 1440px) {
        padding: 40px 120px;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
      }
    }
    &_head {
      height: 480px;
      display: flex;
      justify-content: space-between;
      @media screen and (max-width: 1440px) {
        max-height: 350px;
        height: 100%;
      }
      &-logo {
        &_big {
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          width: 275px;
          height: 100%;
          background: url("~@/assets/images/logo_big.svg");
          background-position: bottom;
          background-repeat: no-repeat;
          background-size: contain;
          @media screen and (max-width: 1680px) {
            width: 230px;
          }
          @media screen and (max-width: 1440px) {
            width: 200px;
          }
        }
        &_small {
          display: flex;
          flex-direction: column;
          align-items: end;
          & .logo_small {
            width: 158px;
            height: 160px;
            background: url("~@/assets/images/logo_small.svg");
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
            @media screen and (max-width: 1680px) {
              width: 130px;
              height: 130px;
            }
            @media screen and (max-width: 1440px) {
              width: 120px;
              height: 120px;
            }
          }
          & .logo_text {
            padding: 20px 0;
            color: #ffffff;
            font-size: 20.057px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            align-items: end;
            text-align: center;
            @media screen and (max-width: 1440px) {
              padding: 15px 0;
              font-size: 18px;
            }
          }

        }
      }
      &-title {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        color: #29ABE2;
        font-size: 349.299px;
        font-style: normal;
        font-weight: 400;
        line-height: 300px;
        @media screen and (max-width: 1680px) {
          font-size: 300px;
          line-height: 250px;
        }
        @media screen and (max-width: 1440px) {
          font-size: 200px;
          line-height: 170px;
        }

      }
    }
    &_title {
      padding: 20px 0 70px;
      @media screen and (max-width: 1440px) {
        padding: 20px 0 40px;
      }
      & h3 {
        color: #FFF;
        font-size: 73.738px;
        font-style: normal;
        font-weight: 700;
        line-height: 70px;
        @media screen and (max-width: 1680px) {
          font-size: 62px;
        }
        @media screen and (max-width: 1440px) {
          font-size: 50px;
          line-height: 60px;
        }
      }
    }
    &_btn {
      margin-right: 60px;
      width: 320px;
      height: 88px;
      border-radius: 16px;
      border: none;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 34px;
      color: #ffffff;
      @media screen and (max-width: 1440px) {
        width: 280px;
        height: 70px;
        font-size: 28px;
      }
      &.sign_up {
        background: #1F4161;
      }
      &.sign_in {
        background: #29DAE3;
      }
      &-container {
        display: flex;
        align-items: center;
      }
    }
  }
</style>
