import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        baseURL: process.env.VUE_APP_BASE_URL,
        access_token: localStorage.getItem('access_token') || '',
        logInUser: localStorage.getItem('logInUser') || {},
    },
    mutations: {
        clearUserData(state) {
            state.access_token = "";
            state.logInUser = {}
            localStorage.removeItem('access_token')
            localStorage.removeItem('logInUser')
        },
        addUserData: (state, data) => {
            state.logInUser = data
        },
    },
    actions: {
        registerUser({state, commit}, data) {
            return axios.post(`${state.baseURL}register`, {
                    name: data.name,
                    surname: data.surname,
                    school_type: data.school_type,
                    email: data.email,
                    password: data.password
                }
            )
                .then((response) => {
                    if (response) {
                        return response
                    }
                })
                .catch((error) => {
                    return error
                })
        },
        login({state, commit}, data) {
            return axios.post(`${state.baseURL}login`, {
                email: data.email,
                password: data.password,
            })
                .then((response) => {
                    if (response) {
                        let access_token = response.data.data.token
                        localStorage.setItem('access_token', access_token)
                        state.access_token = access_token
                        let userData = {
                            email: response.data.data.email,
                            name: response.data.data.name,
                            role: response.data.data.role[0].id
                        }
                        localStorage.setItem('logInUser', JSON.stringify(userData))
                        commit('addUserData', userData)
                        return response
                    }
                })
                .catch((error) => {
                    return error
                })
        },
        getUserDetails({state, commit}) {
            return axios.get(`${state.baseURL}user-details`)
                .then((response) => {
                    let userData = {
                        email: response.data[0].email,
                        name: response.data[0].name,
                        surname: response.data[0].surname,
                        role: response.data[0].roles[0].id
                    }
                    localStorage.setItem('logInUser', JSON.stringify(userData))
                    commit('addUserData', userData)
                    return response
                })
                .catch((error) => {
                    return error
                })
        },
        getAllStudents({state}, data) {
            return axios.get(`${state.baseURL}get-students/${data}`)
                .then((response) => {
                    return response
                })
                .catch((error) => {
                    return error
                })
        },
        createNewLesson({state}, data) {
            return axios.post(`${state.baseURL}create-lesson`, {
                teacher_purpose: data.teacher_purpose,
                student_goals: data.student_goals,
                lesson_title: data.lesson_title,
                teams: data.teams,
                lesson_goals: data.lesson_goals,
                classroom_link: data.classroom_link,
                efficiency_and_result: data.efficiency_and_result,
                class_id: data.class_id,
                subject_id: data.subject_id,
                topic: data.topic,
                share_lesson: data.share_lesson,
                lesson_date: data.lesson_date
            })
                .then((response) => {
                    return response
                })
                .catch((error) => {
                    return error
                })
        },
        lessonUpdate({state}, data) {
            return axios.post(`${state.baseURL}update-lesson/${data.id}`, {
                teacher_purpose: data.teacher_purpose,
                student_goals: data.student_goals,
                lesson_title: data.lesson_title,
                teams: data.teams,
                lesson_goals: data.lesson_goals,
                classroom_link: data.classroom_link,
                efficiency_and_result: data.efficiency_and_result,
                class_id: data.class_id,
                subject_id: data.subject_id,
                topic: data.topic,
                share_lesson: data.share_lesson,
                lesson_date: data.lesson_date
            })
                .then((response) => {
                    return response
                })
                .catch((error) => {
                    return error
                })
        },
        getAllLessons({state}, data) {
            return axios.get(data ?`${state.baseURL}get-lessons/calendar?page=${data}`:`${state.baseURL}get-lessons` )
                .then((response) => {
                    return response
                })
                .catch((error) => {
                    return error
                })
        },
        getLessonData({state}, data) {
            return axios.get(`${state.baseURL}get-lesson/${data}`)
                .then((response) => {
                    return response
                })
                .catch((error) => {
                    return error
                })
        },
        deleteLesson({state}, data) {
            return axios.delete(`${state.baseURL}delete-lesson/${data}`)
                .then((response) => {
                    return response
                })
                .catch((error) => {
                    return error
                })
        },
        getUserRoles({state}) {
            return axios.get(`${state.baseURL}show-edit-role`)
                .then((response) => {
                    return response
                })
                .catch((error) => {
                    return error
                })
        },
        roleChange({state}, data) {
            return axios.post(`${state.baseURL}edit-role`, {
                user_id: data.user_id,
                roles: [data.role_id]
            })
                .then((response) => {
                    return response
                })
                .catch((error) => {
                    return error
                })
        },
        getAllUsers({state}) {
            return axios.get(`${state.baseURL}user-management`)
                .then((response) => {
                    return response
                })
                .catch((error) => {
                    return error
                })
        },
        createNewUser({state}, data) {
            let formData = new FormData()
            formData.append('name', data.name)
            formData.append('surname', data.surname)
            formData.append('email', data.email)
            formData.append('password', data.password)
            formData.append('school_type', data.school_type)
            formData.append('roles[]', 3)
            return axios.post(`${state.baseURL}user-management`, formData)
                .then((response) => {
                    return response
                })
                .catch((error) => {
                    return error
                })
        },
        editUserData({state}, data) {
            let urlencoded = new URLSearchParams();
            urlencoded.append("name", data.name);
            urlencoded.append("surname", data.surname);
            urlencoded.append("roles[]", data.roles ? data.roles.title : null);
            urlencoded.append("status", data.status);
            urlencoded.append("school_type", data.school_type);
            urlencoded.append("password", data.password);
            urlencoded.append("id_class", data.id_class);
            return axios.put(`${state.baseURL}user-management/${data.id}`, urlencoded)
                .then((response) => {
                    return response
                })
                .catch((error) => {
                    return error
                })
        },
        updateMyUser({state}, data) {
            let urlencoded = new URLSearchParams();
            if(data.old_password != null && data.old_password !=  '') {
                urlencoded.append("old_password", data.old_password);
            }
            urlencoded.append("name", data.name);
            urlencoded.append("surname", data.surname);
            urlencoded.append("new_password", data.password);
            urlencoded.append("c_password", data.c_password);
            return axios.put(`${state.baseURL}edit-profile`, urlencoded)
                .then((response) => {
                    return response
                })
                .catch((error) => {
                    return error
                })
        },
        removeUser({state}, data) {
            return axios.delete(`${state.baseURL}user-management/${data}`)
                .then((response) => {
                    return response
                })
                .catch((error) => {
                    return error
                })
        },
        getPhrases({state}) {
            return axios.get(`${state.baseURL}motivation-phrases-management`)
                .then(response => {
                    return response
                })
                .catch(error => {
                    return error
                })
        },
        addNewPhrase({state}, data) {
            return axios.post(`${state.baseURL}motivation-phrases-management`, {
                title: data.title,
                description: data.description
            })
                .then(response => {
                    return response
                })
                .catch(error => {
                    return error
                })
        },
        deletePhrase({state}, data) {
            return axios.delete(`${state.baseURL}motivation-phrases-management/${data}`)
                .then(response => {
                    return response
                })
                .catch(error => {
                    return error
                })
        },
        updatePhrase({state}, data) {
            return axios.put(`${state.baseURL}motivation-phrases-management/${data.id}`, {
                title: data.title,
                description: data.description
            })
                .then(response => {
                    return response
                })
                .catch(error => {
                    return error
                })

        },
        didacticalModelsGet({state}) {
            return axios.get(`${state.baseURL}didactical-models-management`)
                .then((response) => {
                    return response
                })
                .catch((error) => {
                    return error
                })
        },
        createNewModel({state}, data) {
            return axios.post(`${state.baseURL}didactical-models-management`, {
                name: data.name,
                methodological_description: data.methodological_description,
                teachers_and_students_roles: data.teachers_and_students_roles,
                class_settings: data.class_settings,
                evaluation_methods: data.evaluation_methods,
                tools_needed: data.tools_needed,
                criticality_and_possible_solutions: data.criticality_and_possible_solutions,
                extra_content: data.extra_content,
            })
                .then((response) => {
                    return response
                })
                .catch((error) => {
                    return error
                })
        },
        updateModel({state}, data) {
            return axios.put(`${state.baseURL}didactical-models-management/${data.id}`, {
                name: data.name,
                methodological_description: data.methodological_description,
                teachers_and_students_roles: data.teachers_and_students_roles,
                class_settings: data.class_settings,
                evaluation_methods: data.evaluation_methods,
                tools_needed: data.tools_needed,
                criticality_and_possible_solutions: data.criticality_and_possible_solutions,
                extra_content: data.extra_content,
            })
                .then((response) => {
                    return response
                })
                .catch((error) => {
                    return error
                })
        },
        modelDelete({state}, data) {
            return axios.delete(`${state.baseURL}didactical-models-management/${data}`)
                .then((response) => {
                    return response
                })
                .catch((error) => {
                    return error
                })
        },
        getMyModels({state}) {
            return axios.get(`${state.baseURL}get-didactical-models`)
                .then((response) => {
                    return response
                })
                .catch((error) => {
                    return error
                })
        },
        addDidacticalModel({state}, data) {
            return axios.post(`${state.baseURL}add-didactical-model/${data}`)
                .then((response) => {
                    return response
                })
                .catch((error) => {
                    return error
                })
        },
        getAdminHomeData({state}) {
            return axios.get(`${state.baseURL}home-page`)
                .then((response) => {
                    return response
                })
                .catch((error) => {
                    return error
                })
        },
        getSubjectClassTopic({state}, lessonId) {
            return axios.get(`${state.baseURL}get-subject-classes${lessonId ? '/' + lessonId : ""}`)
                .then((response) => {
                    return response
                })
                .catch((error) => {
                    return error
                })
        },
        getCommunityFilters({state}) {
            return axios.get(`${state.baseURL}get-community-filters-keys`)
                .then((response) => {
                    return response
                })
                .catch((error) => {
                    return error
                })
        },
        getCommunities({state}) {
            return axios.get(`${state.baseURL}get-community-lessons`)
                .then((response) => {
                    return response
                })
                .catch((error) => {
                    return error
                })
        },
        methodologiesGet({state}) {
            return axios.get(`${state.baseURL}methodologies-management`)
                .then((response) => {
                    return response
                })
                .catch((error) => {
                    return error
                })
        },
        createNewMethodology({state}, data) {
            return axios.post(`${state.baseURL}methodologies-management`, {
                name: data.name,
                methodological_description: data.methodological_description,
                teachers_and_students_roles: data.teachers_and_students_roles,
                class_settings: data.class_settings,
                evaluation_methods: data.evaluation_methods,
                tools_needed: data.tools_needed,
                criticality_and_possible_solutions: data.criticality_and_possible_solutions,
                extra_content: data.extra_content,
            })
                .then((response) => {
                    return response
                })
                .catch((error) => {
                    return error
                })
        },
        methodologyDelete({state}, data) {
            return axios.delete(`${state.baseURL}methodologies-management/${data}`)
                .then((response) => {
                    return response
                })
                .catch((error) => {
                    return error
                })
        },
        getAllURLs({state}) {
            return axios.get(`${state.baseURL}get-general-settings`)
                .then((response) => {
                    return response
                })
                .catch((error) => {
                    return error
                })
        },
        updateURL({state}, data) {
            return axios.post(`${state.baseURL}general-settings-management`, {
                rating_url: data.rating_url,
                middle_school_url: data.middle_school_url,
                high_school_url: data.high_school_url,
                id: data.id
            })
                .then((response) => {
                    return response
                })
                .catch((error) => {
                    return error
                })
        },
        synchronizeData({state}, data) {
            return axios.post(`${state.baseURL}teachers-tudents-sync`, {
                sync: data
            })
                .then((response) => {
                    return response
                })
                .catch((error) => {
                    return error
                })
        },
        forgotEmail({state}, data) {
            return axios.post(`${state.baseURL}password/email`, {
                email: data
            })
                .then((response) => {
                    return response
                })
                .catch((error) => {
                    return error
                })
        },
        sendEmailCode({state}, data) {
            return axios.post(`${state.baseURL}password/code/check`, {
                code: data
            })
                .then((response) => {
                    return response
                })
                .catch((error) => {
                    return error
                })
        },
        resetPassword({state}, data) {
            return axios.post(`${state.baseURL}password/reset`, {
                code: data.code,
                password: data.password,
                c_password: data.c_password
            })
                .then((response) => {
                    return response
                })
                .catch((error) => {
                    return error
                })
        }
    },
    getters: {
        accessToken: state => state.access_token,
        logUserData: state => state.logInUser,
    },
    modules: {}
})
