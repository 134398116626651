import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
import Home from '../views/Home.vue'
import SignIn from '../components/Auth/SignIn'
import SignUp from '../components/Auth/SignUp'
import PageNotFound from "../views/PageNotFound";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {auth: false}
  },
  {
    path: "/sign_in",
    name: "SignIn",
    component: SignIn,
    meta: {auth: false}
  },
  {
    path: "/sign_up",
    name: "SignUp",
    component: SignUp,
    meta: {auth: false}
  },
    //teacher
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: function () {
      return import(/* webpackChunkName: "dashboard" */ '../views/Dashboard')
    },
    meta: {layout: 'dashboard', auth: true, type: 3},
    props: true
  },
  {
    path: '/new_lesson',
    name: 'NewLesson',
    meta: {layout: 'dashboard', auth: true, type: 3},
    component: function () {
      return import(/* webpackChunkName: "new_lesson" */ '../views/NewLesson')
    }
  },
  {
    path: '/my_lessons',
    name: 'MYLessons',
    meta: {layout: 'dashboard', auth: true, type: 3},
    component: function () {
      return import(/* webpackChunkName: "my_lessons" */ '../views/MyLessons')
    }
  },
  {
    path: '/lesson_edit/:id',
    name: 'LessonEdit',
    meta: {layout: 'dashboard', auth: true, type: 3},
    component: function () {
      return import(/* webpackChunkName: "lesson_edit" */ '../views/NewLesson')
    }
  },
  {
    path: '/calendar',
    name: 'Calendar',
    meta: {layout: 'dashboard', auth: true, type: 3},
    component: function () {
      return import(/* webpackChunkName: "calendar" */ '../views/Calendar')
    }
  },
  {
    path: '/didactical_models',
    name: 'DidacticalModels',
    meta: {layout: 'dashboard', auth: true, type: 3},
    component: function () {
      return import(/* webpackChunkName: "didactical_models" */ '../views/DidacticalModels')
    }
  },
  {
    path: '/community',
    name: 'Community',
    meta: {layout: 'dashboard', auth: true, type: 3},
    component: function () {
      return import(/* webpackChunkName: "community" */ '../views/Community')
    }
  },
  {
    path: '/settings',
    name: 'Settings',
    meta: {layout: 'dashboard', auth: true, type: 3},
    component: function () {
      return import(/* webpackChunkName: "settings" */ '../views/Settings')
    }
  },
    //admin
  {
    path: '/admin_dashboard',
    name: 'AdminContent',
    meta: {layout: 'admin', auth: true, type: 1},
    component: function () {
      return import(/* webpackChunkName: "admin" */ '../views/admin/AdminContent')
    }
  },
  {
    path: '/manage_users',
    name: 'ManageUsers',
    meta: {layout: 'admin', auth: true, type: 1},
    component: function () {
      return import(/* webpackChunkName: "manage_users" */ '../views/admin/ManageUsers')
    }
  },
  {
    path: '/admin_new_lesson',
    name: 'AdminNewLesson',
    meta: {layout: 'admin', auth: true, type: 1},
    component: function () {
      return import(/* webpackChunkName: "admin_new_lesson" */ '../views/admin/AdminNewLesson')
    }
  },
  {
    path: '/admin_lessons',
    name: 'AdminLessons',
    meta: {layout: 'admin', auth: true, type: 1},
    component: function () {
      return import(/* webpackChunkName: "admin_lessons" */ '../views/admin/AdminLessons')
    }
  },
  {
    path: '/admin_edit_lesson/:id',
    name: 'AdminEditLesson',
    meta: {layout: 'admin', auth: true, type: 1},
    component: function () {
      return import(/* webpackChunkName: "admin_edit_lesson" */ '../views/admin/AdminNewLesson')
    }
  },
  {
    path: '/motivation_phrases',
    name: 'MotivationalPhrases',
    meta: {layout: 'admin', auth: true, type: 1},
    component: function () {
      return import(/* webpackChunkName: "motivation_phrases" */ '../views/admin/MotivationalPhrases')
    }
  },
  {
    path: '/admin_methodologies',
    name: 'AdminMethodologies',
    meta: {layout: 'admin', auth: true, type: 1},
    component: function () {
      return import(/* webpackChunkName: "admin_methodologies" */ '../views/admin/AdminMethodologies')
    }
  },
  {
    path: '/admin_did_models',
    name: 'AdminDidacticalModels',
    meta: {layout: 'admin', auth: true, type: 1},
    component: function () {
      return import(/* webpackChunkName: "admin_did_models" */ '../views/admin/AdminDidacticalModels')
    }
  },
  {
    path: '/general_settings',
    name: 'GeneralSettings',
    meta: {layout: 'admin', auth: true, type: 1},
    component: function () {
      return import(/* webpackChunkName: "general_settings" */ '../views/admin/GeneralSettings')
    }
  },



  {
    path: '/404',
    name: '404',
    component: PageNotFound,
    meta: {auth: false}
  },
  {
    path: '*',
    redirect: '/404'
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  let user = JSON.parse(localStorage.getItem('logInUser'))
  if(to.meta.auth && !store.getters['accessToken']) {
    store.commit('clearUserData')
    return next({name: 'SignIn'})
  }
  if( to.meta.auth && from.name !== 'SignIn' && from.name !== 'SignUp') {
    if(to.meta.type !== user.role) {
      return next({name: '404'})
    }
  }
  return next()
});

export default router
