<template>
    <div class="page_content">
        <div class="page_head">
            <div class="page_head-logo_big"></div>
            <h1 class="page_head-title">
                404
            </h1>
        </div>
        <div class="page_title">
            <h3>PAGE NOT FOUND </h3>

        </div>
        <div class="page_btn-container">
            <b-button class="page_btn" @click="backToHome()">GO BACK</b-button>

        </div>
    </div>


<!--    <div class="not-found-page">-->
<!--        <p @click="backToHome()">Back to Home</p>-->

<!--        <h1 class="text-capitalize">404</h1>-->
<!--        <h1 class="text-capitalize">Page Not Found</h1>-->
<!--    </div>-->
</template>

<script>
    export default {
        name: "PageNotFound",
        data() {
            return {

            }
        },
        methods: {
            backToHome() {
                let user = JSON.parse(localStorage.getItem('logInUser'))
                if(user) {
                    if(user.role === 1) {
                        this.$router.push('/admin_dashboard')
                    } else if(user.role === 2) {
                        this.$router.push('/')
                    } else if(user.role === 3) {
                        this.$router.push('/dashboard')
                    }
                } else {
                    this.$router.push('/')
                }
            }
        }
    }
</script>

<style scoped lang="scss">
    .page {
        &_content {
            width: 100vw;
            height: 100vh;
            background: #00A99D;
            padding: 58px 120px 58px 150px;
        }
        &_head {
            height: 480px;
            display: flex;
            justify-content: center;
            &-logo {
                &_big {
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-end;
                    width: 275px;
                    height: 100%;
                    background: url("~@/assets/images/logo_big.svg");
                    background-position: bottom;
                    background-repeat: no-repeat;
                    background-size: contain;
                }
            }
            &-title {
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: flex-end;
                color: #29ABE2;
                font-size: 349.299px;
                font-style: normal;
                font-weight: 400;
                line-height: 300px;

            }
        }
        &_title {
            padding: 20px 0 70px;
            display: flex;
            justify-content: center;
            & h3 {
                color: #FFF;
                font-size: 73.738px;
                font-style: normal;
                font-weight: 700;
                line-height: 70px;
            }
        }
        &_btn {
            margin-right: 60px;
            width: 320px;
            height: 88px;
            border-radius: 16px;
            border: none;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 34.016px;
            color: #ffffff;
            background: #1F4161;
            &.sign_in {
                background: #29DAE3;
            }
            &:hover {
                background: #29DAE3;
            }
            &-container {
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
    }
    /*.not-found-page {*/
    /*    display: flex;*/
    /*    justify-content: center;*/
    /*    align-items: center;*/
    /*    flex-direction: column;*/
    /*    padding-top: 50px;*/
    /*    height: 100vh;*/
    /*    background: transparent linear-gradient(180deg, #3E8ECB 0%, #096BC4 100%) 0% 0% no-repeat padding-box;*/
    /*    font-weight: bold;*/
    /*    text-shadow: 3px 3px 3px #66a8ff;*/
    /*    color: white;*/
    /*    & p {*/
    /*        color: #fff;*/
    /*        cursor: pointer;*/
    /*    }*/
    /*}*/
</style>
