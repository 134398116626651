<template>
    <div class="w-100 d-flex">
        <div class="layer_container">
            <div class="sidebar_container">
                <NavBar :sideBar="sideBar" @changeBar="changeSideBar"/>
            </div>
            <div class="main_container" :class="{'closeBar': sideBar}">
                <slot></slot>
            </div>
        </div>
    </div>
</template>

<script>
    import NavBar from "../NavBar";
    export default {
        name: "Dashboard",
        data() {
            return {
                sideBar: false
            }
        },
        components: {NavBar},
        methods: {
            changeSideBar() {
                this.sideBar = !this.sideBar
            },
        }
    }
</script>

<style scoped lang="scss">
.layer {
    &_container {
        width: 100%;
        height: calc(100% - 73px);
        display: flex;
    }
}
    .sidebar_container {
        margin-right: 0 !important;
        max-width: 310px;
        position: fixed;
    }
    .main_container {
        width: 100%;
        margin-left: 310px;
        transition: 0.4s;
        &.closeBar {
            margin-left: 150px;
            transition: 0.4s;
        }
    }
</style>
