<template>
  <div class="page_container">
    <div class="page_logo" @click="goHome()"></div>
    <div class="card_container">
      <div class="card_title">Login on <span class="believe">BELIEVE</span></div>
      <form @submit.prevent="loginUser()">
        <div class="input_item">
          <b-form-input
              id="email"
              v-model="userForm.email"
              type="email"
              placeholder="Enter your email"
              :class="{ 'is-invalid': isSubmitted && $v.userForm.email.$error }"
              trim
          >
          </b-form-input>
          <div v-if="isSubmitted && $v.userForm.email.$error" class="invalid-feedback">
            <span v-if="!$v.userForm.email.required">Email field is required</span>
            <span v-if="!$v.userForm.email.email">Please provide valid email</span>
          </div>
        </div>
        <div class="input_item">
          <b-form-input
              id="password"
              :type="l_password"
              v-model="userForm.password"
              placeholder="Enter your password"
              :class="{ 'is-invalid': isSubmitted && $v.userForm.password.$error }"
              trim
          ></b-form-input>
          <div :class="{'eye_close': l_password == 'password', 'eye_open': l_password == 'text',}"
               @click="showPassword()"></div>
          <div v-if="isSubmitted && $v.userForm.password.$error" class="invalid-feedback">
            <span v-if="!$v.userForm.password.required">Password field is required</span>
            <span v-if="!$v.userForm.password.minLength">Password should be at least 6 characters long</span>
          </div>
        </div>

        <div v-if="loginError" class="text_danger">
          <span>{{ loginError }}</span>
        </div>

        <b-button class="sign_btn" type="submit">Login</b-button>
        <div class="forgot_container">
          <div class="forgot_pass" id="forgot_btn" @click="forgotModal()">Forgot your password</div>
          <p class="forgot_options">or use one of these options</p>
        </div>
        <div class="options_btn-container">
          <div class="options_btn google" @click="otherOption('google')"></div>
          <div class="options_btn apple" @click="otherOption('apple')"></div>
          <div class="options_btn microsoft" @click="otherOption('microsoft')"></div>
          <div class="options_btn system" @click="otherOption('system')"></div>
        </div>
        <div class="account_container">
          <div class="account_text">Do you want to create a new account? &nbsp;<router-link to="/sign_up">
            <div class="sign_text"> Register</div>
          </router-link>
          </div>
          <div class="account_faq">After you read our&nbsp;<div class="faq_text">FAQ</div>&nbsp;you can reach out for
            technical assistance at
          </div>
        </div>
      </form>
    </div>

    <!-- forget password modal-->
    <b-modal ref="forget-modal" centered size="md" hide-header hide-footer>
      <div class="modal_content" v-if="!forgot_code_status">
        <div class="modal_title">
          Forgot password
        </div>
        <div class="modal_text">Enter your email address and we will send you a link to reset your password.</div>
        <div class="modal_input">
          <b-form-input v-model="forgot_email" placeholder="Email"></b-form-input>
        </div>

        <div class="modal_btn">
          <b-button class="cancel_btn mx-2" @click="forgotModal()">Cancel</b-button>
          <b-button class="add_btn mx-2" @click="sendForgotEmail()">Send</b-button>
        </div>
      </div>
      <div v-if="forgot_code_status" class="modal_content">

        <div class="modal_title">
          Reset Password code
        </div>
        <div class="modal_text">Please enter code we sent you via email.</div>
        <div class="modal_input">
          <b-form-input v-model="forgot_code" type="number" placeholder="Code"></b-form-input>
        </div>

        <div class="modal_btn">
          <b-button class="cancel_btn mx-2" @click="forgotModal()">Cancel</b-button>
          <b-button class="add_btn mx-2" @click="sendForgotCode()">Send</b-button>
        </div>

      </div>
    </b-modal>
    <!-- forget password modal-->

    <!-- reset password modal-->
    <b-modal ref="reset-modal" centered size="md" hide-header hide-footer>
      <div class="modal_content">
        <div class="modal_title">
          Reset password
        </div>
        <div class="modal_text">Enter your new password</div>
        <div class="modal_input">
          <b-form-input v-model="reset_pass_data.new_password" :type="r_pass" placeholder="New Password"></b-form-input>
          <div :class="{'eye_close': r_pass == 'password', 'eye_open': r_pass == 'text'}"
               @click="showPass('r_pass')"></div>
        </div>
        <div class="modal_input">
          <b-form-input v-model="reset_pass_data.confirm_password" :type="r_confirm" placeholder="Confirm Password"></b-form-input>
          <div :class="{'eye_close': r_confirm == 'password', 'eye_open': r_confirm == 'text',}"
               @click="showPass('r_confirm')"></div>
        </div>

        <div class="modal_btn">
          <b-button class="cancel_btn mx-2" @click="resetModal()">Cancel</b-button>
          <b-button class="add_btn mx-2" @click="sendResetPass()">Reset</b-button>
        </div>
      </div>
    </b-modal>
    <!-- reset password modal-->
  </div>
</template>

<script>
import {required, email, minLength} from "vuelidate/lib/validators";
import {mapActions} from "vuex";

export default {
  name: "SignIn",
  data() {
    return {
      userForm: {
        email: "",
        password: ""
      },
      loginError: null,
      isSubmitted: false,
      l_password: 'password',
      r_pass: 'password',
      r_confirm: 'password',
      forgot_email: '',
      forgot_code: '',
      forgot_code_status: false,
      reset_pass_data: {
        new_password: '',
        confirm_password: ''
      }

    }
  },
  validations: {
    userForm: {
      email: {
        required,
        email
      },
      password: {
        required,
        minLength: minLength(6)
      },
    }
  },
  methods: {
    ...mapActions(['login', 'forgotEmail', 'sendEmailCode', 'resetPassword']),
    goHome() {
      this.$router.push("/")
    },
    showPassword() {
      if (this.l_password === 'password') {
        this.l_password = 'text'
      } else {
        this.l_password = 'password'
      }
    },
    showPass(item) {
      if (item === 'r_pass') {
        if (this.r_pass === 'password') {
          this.r_pass = 'text'
        } else {
          this.r_pass = 'password'
        }
      } else {
        if (this.r_confirm === 'password') {
          this.r_confirm = 'text'
        } else {
          this.r_confirm = 'password'
        }
      }
    },
    forgotModal() {
      this.forgot_code_status = false
      this.forgot_email = ''
      this.forgot_code = ''
      this.$refs['forget-modal'].toggle('#forgot_btn')
    },
    resetModal() {
      this.reset_pass_data.new_password = ''
      this.reset_pass_data.confirm_password = ''
      this.$refs['reset-modal'].toggle('#reset_btn')
    },
    sendResetPass() {
      let sendData = {
        code: this.forgot_code,
        password: this.reset_pass_data.new_password,
        c_password: this.reset_pass_data.confirm_password
      }
      this.resetPassword(sendData)
      .then(res => {
        if(res.status == 200) {
          this.resetModal()
        }
      })
    },
    sendForgotEmail() {
      this.forgotEmail(this.forgot_email)
      .then(res => {
        if(res.status == 200 && res.data.code) {
          this.forgot_code_status = true
        }
      })
    },
    sendForgotCode() {
      this.sendEmailCode(this.forgot_code)
      .then(res => {
        if(res.status == 200 && res.data.message == "Code confirmed") {
          this.$refs['forget-modal'].toggle('#forgot_btn')
          setTimeout(() => {
            this.resetModal()
          }, 500)
        }
      })
    },
    otherOption(option) {
      console.log(option)
    },
    loginUser() {
      this.isSubmitted = true;
      this.loginError = null
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      } else {
        this.login(this.userForm)
            .then((res) => {
              if (res.status === 200) {
                let role_id = res.data.data.role[0].id
                if (role_id == 1) {
                  this.$router.push('/admin_dashboard')
                } else if (role_id == 3) {
                  this.$router.push('/dashboard')
                } else {
                  console.log('no admin')
                }

              } else {
                this.loginError = res.response.data.error
                // this.loginError = res.response.data.error
              }
            })
      }
    }
  }
}
</script>

<style scoped lang="scss">
.page {
  &_container {
    width: 100vw;
    height: 100vh;
    background: url("~@/assets/images/background_1.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    & .card {
      &_container {
        width: 695px;
        height: 780px;
        background: #ffffff;
        border-radius: 50px;
        padding: 100px;
        display: flex;
        flex-direction: column;
        align-items: center;
        @media screen and (max-width: 1440px) {
          padding: 50px 100px;
          max-height: 680px;
          height: 100%;
        }

        & form {
          width: 100%;
        }

        & .input_item {
          padding: 10px 0;
          width: 100%;
          position: relative;

          & input {
            width: 100%;
            height: 57px;
            border-radius: 30px;
            border: 1px solid #EEE;
            padding: 0 26px;

            &::placeholder {
              color: #333;
              font-family: 'Poppins', sans-serif;
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
            }
          }

          & .eye_open {
            position: absolute;
            width: 24px;
            height: 24px;
            right: 20px;
            top: 28px;
            background: url("~@/assets/icons/eye_open.svg");
            background-repeat: no-repeat;
            background-position: center;
            background-size: contain;
            cursor: pointer;
          }

          & .eye_close {
            position: absolute;
            width: 24px;
            height: 24px;
            right: 20px;
            top: 28px;
            background: url("~@/assets/icons/eye_close.svg");
            background-repeat: no-repeat;
            background-position: center;
            background-size: contain;
            cursor: pointer;
          }
        }

        & .sign_btn {
          margin-top: 10px;
          width: 100%;
          height: 57px;
          border-radius: 30px;
          background: #C2A9FD;
          border: none;

        }

        & .form-control:focus {
          outline: none;
          box-shadow: none;
          background: #FAFFBD;
        }

        & .forgot {
          &_container {
            padding: 20px 0;
            display: flex;
            flex-direction: column;
            align-items: center;
          }

          &_pass {
            color: #522EA8;
            font-family: 'Poppins', sans-serif;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            text-decoration-line: underline;
            cursor: pointer;
          }

          &_options {
            padding-top: 17px;
          }
        }

        & .options_btn {
          width: 93px;
          height: 93px;
          cursor: pointer;
          @media screen and (max-width: 1440px) {
            width: 70px;
            height: 70px;
          }

          &-container {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            @media screen and (max-width: 1440px) {
              justify-content: space-evenly;
            }
          }

          &.google {
            background: url("~@/assets/images/google_btn.svg") no-repeat center;
            background-size: contain;
          }

          &.apple {
            background: url("~@/assets/images/apple_btn.svg") no-repeat center;
            background-size: contain;
          }

          &.microsoft {
            background: url("~@/assets/images/microsoft_btn.svg") no-repeat center;
            background-size: contain;
          }

          &.system {
            background: url("~@/assets/images/system_btn.svg") no-repeat center;
            background-size: contain;
          }
        }

        & .account {
          &_container {
            padding: 34px 0;
            display: flex;
            flex-direction: column;
            align-items: center;
          }

          &_text {
            display: flex;
            color: #000;
            font-family: 'Poppins', sans-serif;
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;

            & a {
              opacity: 0.7;
              color: #846ABF;
              font-family: 'Poppins', sans-serif;
              font-size: 14px;
              font-style: normal;
              font-weight: 600;
              line-height: normal;
              text-decoration-line: underline;
            }
          }

          &_faq {
            color: #000;
            font-family: 'Poppins', sans-serif;
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            display: flex;
            padding-top: 20px;

            & .faq_text {
              opacity: 0.7;
              color: #A694CF;
              font-family: 'Poppins', sans-serif;
              font-size: 14px;
              font-style: normal;
              font-weight: 600;
              line-height: normal;
            }
          }
        }
      }

      &_title {
        color: #333;
        font-size: 26px;
        font-weight: 600;
        padding-bottom: 26px;

        & .believe {
          font-family: 'Poppins', sans-serif;
          font-size: 26px;
          font-weight: 300;
        }
      }
    }
  }

  &_logo {
    position: absolute;
    top: 50px;
    left: 50px;
    width: 72px;
    height: 72px;
    background: url("~@/assets/images/logo_small.svg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    cursor: pointer;
  }
}

.modal {
  &_content {
    padding: 10px 20px;
  }

  &_title {
    color: #11334D;
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    padding: 10px 0;
  }

  &_text {
    padding: 20px 0;
    color: #11334D;
    font-size: 16px;
    font-style: italic;
    font-weight: 600;
    line-height: normal;
  }

  &_input {
    margin: 10px 0;
    width: 100%;
    display: flex;
    align-items: center;
    position: relative;
    & .eye_close {
      position: absolute;
      width: 30px;
      height: 36px;
      right: 15px;
      top: 1px;
      background-image: url("~@/assets/icons/eye_close.svg");
      background-repeat: no-repeat;
      background-position: center;
      cursor: pointer;
    }

    & .eye_open {
      position: absolute;
      width: 30px;
      height: 36px;
      right: 15px;
      top: 1px;
      background-image: url("~@/assets/icons/eye_open.svg");
      background-repeat: no-repeat;
      background-position: center;
      cursor: pointer;
    }

    & input {
      border: 1px solid #11334D;

      &::placeholder {
        color: #11334D;
        font-size: 18px;
        font-style: italic;
        font-weight: 500;
        line-height: normal;
      }
    }
  }

  &_btn {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 20px 0 10px;

    & .cancel_btn,
    & .add_btn {
      height: 40px;
      border-radius: 12px;
      border: 1px solid #FFFFFF;
      background: #00A99D;
      color: #FFFFFF;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 5px 20px;
      cursor: pointer;
    }
  }
}

::v-deep .form-control.is-invalid {
  background-image: none !important;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
</style>
