import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import Default from './components/layouts/Default'
import Dashboard from './components/layouts/Dashboard'
import Admin from "./components/layouts/Admin"
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import Vuelidate from 'vuelidate'
import interceptorsSetup from '@/helpers/interceptors'
import { BFormTags } from 'bootstrap-vue'
import FullCalendar from 'vue-full-calendar'
import 'fullcalendar/dist/fullcalendar.css'


Vue.component('b-form-tags', BFormTags)
Vue.use(FullCalendar)
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(Vuelidate)
Vue.component('admin-layout', Admin);
Vue.component('dashboard-layout', Dashboard);
Vue.component('default-layout', Default);
Vue.config.productionTip = false
interceptorsSetup()
new Vue({
  router,
  store,
  render: function (h) { return h(App) }
}).$mount('#app')
